import { Button, Upload } from 'tea-component'
import JobModel from './jobModel'
import { useRef } from 'react'

const onDragStart = (event, nodeType) => {
  event.dataTransfer.setData('application/reactflow', nodeType)
  event.dataTransfer.effectAllowed = 'move'
}

const Sidebar = ({ importjson, exportjson }) => {
  const jobRef = useRef(null)
  return (
    <aside className='aside'>
      <div
        className='asideitem'
        onDragStart={event => onDragStart(event, 'DataSet')}
        draggable
      >
        DataSet
      </div>
      <div
        className='asideitem'
        onDragStart={event => onDragStart(event, 'Mock')}
        draggable
      >
        Mock
      </div>
      <div
        className='asideitem'
        onDragStart={event => onDragStart(event, 'Kafka')}
        draggable
      >
        Kafka
      </div>

      <div className='asideitem-right'>
        <Button
          className='itembtn'
          onClick={() => jobRef.current.open()}
        >
          作业配置
        </Button>
        <Upload
          accept='.json'
          beforeUpload={file => {
            importjson(file)
            return false
          }}
        >
          <Button className='itembtn'>导入json</Button>
        </Upload>
        <Button className='itembtn' onClick={exportjson} type='primary'>
          生成json
        </Button>
      </div>
      <JobModel ref={jobRef} />
    </aside>
  )
}

export default Sidebar

import React, { useState, forwardRef, useImperativeHandle } from 'react'
import { Modal, Button, Text, message } from 'tea-component'

export default forwardRef(({}, ref) => {
  const [visible, setVisible] = useState(false)
  const [dataJson, setDataJson] = useState({})

  const close = () => setVisible(false)

  useImperativeHandle(ref, () => ({
    open: data => {
      setDataJson(data)
      setVisible(true)
    }
  }))

  return (
    <>
      <Modal visible={visible} caption='Json结果' onClose={close}>
        <Modal.Body>
          <pre
            style={{
              whiteSpace: 'pre-wrap',
              wordWrap: 'break-word',
              maxHeight: '500px',
              overflowY: 'auto',
              backgroundColor: '#f3f4f7',
              padding: '10px'
            }}
          >
            <Text copyable>{dataJson}</Text>
          </pre>
        </Modal.Body>
        <Modal.Footer>
          <Button
            type='primary'
            onClick={() => {
              const jsonData = dataJson
              const blob = new Blob([jsonData], { type: 'application/json' })
              const url = URL.createObjectURL(blob)
              const link = document.createElement('a')
              link.href = url
              link.download = 'data.json'
              document.body.appendChild(link)
              link.click()
              document.body.removeChild(link)
              URL.revokeObjectURL(url)
              close()
            }}
          >
            下载
          </Button>
          <Button
            type='weak'
            onClick={() => {
              navigator.clipboard.writeText(dataJson)
              message.success({
                content: '复制成功!'
              })
            }}
          >
            复制
          </Button>
          <Button type='weak' onClick={close}>
            关闭
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
})

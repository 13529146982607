import { useState, useEffect, useRef } from 'react'
import {
  Button,
  Drawer,
  Form,
  Input,
  InputNumber,
  Select,
  DatePicker,
  Tabs,
  InputAdornment,
  Text,
  Table,
  PopConfirm,
  Icon
} from 'tea-component'
import { useForm, Controller } from 'react-hook-form'
import NodeCol from './nodeCol'
import { colType, colStrategy, dependType } from './defaultNode'
import EdgeContent from './edgeContent'

const NodeContent = ({ info, onChange, onDependChange }) => {
  const [visible, setVisible] = useState(false)
  const { control, handleSubmit, formState, reset } = useForm({ mode: 'all' })
  const nodeRef = useRef(null)
  const edgeRef = useRef(null)

  const [columns, setColumns] = useState([])
  const [resetInfo, setResetInfo] = useState({})
  const [dependInfoList, setDependInfoList] = useState([])
  const [activeId, setActiveId] = useState('tableconf')

  const [oldInfo, setOldInfo] = useState({})

  useEffect(() => {
    // if (info?.id === resetInfo.id && visible) {
    //   return
    // }

    setResetInfo(info || {})
    if (info && info.nodeType === 'Mock') {
      if (oldInfo.id !== info.id) {
        setActiveId('tableconf')
      }
      if (visible) {
        setVisible(false)
        setTimeout(() => setVisible(true), 20)
      } else {
        setVisible(true)
      }
      setOldInfo(info)
      setColumns(info.columns || [])
      setDependInfoList(info.dependInfo || [])
      reset(info)
    } else {
      setVisible(false)
      reset({})
      setColumns([])
      setDependInfoList([])
      setOldInfo({})
    }
  }, [info])

  async function onSubmit(values) {
    const dataset = { ...values, columns, dependInfo: dependInfoList }
    onChange(dataset, true)
    console.log('dataset', dataset)
    setVisible(false)
  }

  function getStatus(meta) {
    if (!meta.isDirty && !formState.isSubmitted) {
      return null
    }
    return meta.invalid ? 'error' : 'success'
  }

  const tableHtml = (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Form>
        <Controller
          name='name'
          control={control}
          rules={{
            required: '请输入表名'
          }}
          render={({ field, fieldState }) => (
            <Form.Item
              label='表名'
              status={getStatus(fieldState)}
              message={fieldState.error?.message}
            >
              <Input
                {...field}
                autoComplete='off'
                maxLength={20}
                placeholder='请输入表名'
              />
            </Form.Item>
          )}
        />

        <Controller
          name='totalLines'
          control={control}
          rules={{
            required: '请输入生成行数'
          }}
          render={({ field, fieldState }) => (
            <Form.Item
              label='生成行数'
              status={getStatus(fieldState)}
              message={fieldState.error?.message}
              extra='-1表示无限制一直生成'
            >
              <InputNumber
                {...field}
                placeholder='-1表示无限制一直生成'
                min={-1}
                inputProps={{ style: { width: '82px' } }}
              />
            </Form.Item>
          )}
        />
        <Controller
          name='partitionNums'
          control={control}
          rules={{
            required: '请输入生成分区数'
          }}
          render={({ field, fieldState }) => (
            <Form.Item
              label='生成分区数'
              status={getStatus(fieldState)}
              message={fieldState.error?.message}
            >
              <InputNumber
                {...field}
                placeholder='请输入生成分区数'
                min={1}
                inputProps={{ style: { width: '66px' } }}
              />
            </Form.Item>
          )}
        />
        <Controller
          name='bucketNums'
          control={control}
          rules={{
            required: '请输入分桶数'
          }}
          render={({ field, fieldState }) => (
            <Form.Item
              label='分桶数'
              status={getStatus(fieldState)}
              message={fieldState.error?.message}
              extra='一个分桶一输出一个文件使用一个线程进行处理'
            >
              <InputNumber
                {...field}
                placeholder='请输入分桶数'
                min={1}
                inputProps={{ style: { width: '66px' } }}
              />
            </Form.Item>
          )}
        />
        <Controller
          name='col_null_value'
          control={control}
          render={({ field, fieldState }) => (
            <Form.Item label='空值标识'>
              <InputAdornment
                before={
                  <Select
                    options={['null', '自定义'].map(value => ({
                      value
                    }))}
                    value={field.value === null ? 'null' : '自定义'}
                    onChange={value => {
                      field.onChange(value === 'null' ? null : '')
                    }}
                  />
                }
              >
                {field.value !== null && (
                  <Input {...field} placeholder='不填时为空字符' />
                )}
              </InputAdornment>
            </Form.Item>
          )}
        />
        <Controller
          name='out_format'
          control={control}
          render={({ field, fieldState }) => (
            <Form.Item label='输出内容格式'>
              <Select
                {...field}
                appearance='button'
                options={[
                  { text: '输出json', value: 'json' },
                  { text: '只输出value', value: 'only_v' },
                  { text: 'key加value', value: 'k_and_v' }
                ]}
              />
            </Form.Item>
          )}
        />

        <Controller
          name='col_split'
          control={control}
          rules={{
            required: '请输入分隔符'
          }}
          render={({ field, fieldState }) => (
            <Form.Item
              label='列分隔符'
              extra='列之间的分割符号，默认 `'
              status={getStatus(fieldState)}
              message={fieldState.error?.message}
            >
              <Input {...field} maxLength={1} placeholder='默认、' />
            </Form.Item>
          )}
        />

        <Controller
          name='col_kv_split'
          control={control}
          render={({ field, fieldState }) => (
            <Form.Item
              label='key-value分隔符'
              extra='如果[输出格式]为[key加value]是,k和v之间的分割符号。'
            >
              <Input {...field} maxLength={1} placeholder='' />
            </Form.Item>
          )}
        />

        <Controller
          name='output'
          control={control}
          render={({ field, fieldState }) => (
            <Form.Item label='输出目标' extra='输出的目标。默认输出到文件。'>
              <Select
                {...field}
                appearance='button'
                options={[
                  { text: '打印到控制台', value: 'print' },
                  { text: '输出到kafka', value: 'kafka' },
                  {
                    text: '同时出入到文件和kalka',
                    value: 'file_kafka'
                  },
                  { text: '输出到文件', value: 'file' }
                ]}
              ></Select>
            </Form.Item>
          )}
        />

        <Controller
          name='output_kafka_topic'
          control={control}
          render={({ field, fieldState }) => (
            <Form.Item
              label='输出目标kafka'
              extra='当输出目标有kafka时，这个输出kakfa的topic'
            >
              <Input {...field} placeholder='输出目标' />
            </Form.Item>
          )}
        />

        <Controller
          name='partitionUnit'
          control={control}
          render={({ field, fieldState }) => (
            <Form.Item
              label='partitionUnit'
              extra='yyyyMM 表示月为单位;yyyyMMDD 表示天为单位;yyyyMMDDHH 表示小时为单位,now表示当前时间(默认值)'
            >
              <Select
                {...field}
                appearance='button'
                options={[
                  { text: 'yyyyMM', value: 'yyyyMM' },
                  { text: 'yyyyMMDD', value: 'yyyyMMDD' },
                  { text: 'yyyyMMDDHH', value: 'yyyyMMDDHH' },
                  { text: 'now', value: 'now' }
                ]}
              ></Select>
            </Form.Item>
          )}
        />
        <Controller
          name='partitionStart'
          control={control}
          render={({ field, fieldState }) => (
            <Form.Item
              label='partitionStart'
              extra='1、如果partitionUnit 为now时,所有的分区都是生成时候的时间; 
             2、如果非默认值partitionUnit,第一个分区的时间为partitionStart 所在的partitionUnit 开始和结束时间内的任意时间。比如起始时间为 2023-04-23 12:32:34 ，partitionUnit为小时。那么第一个分区内的时间为2023-04-23 12:00:00 - 2023-04-23 13:00:00,那么第一个分区内的时间为2023-04-23 13:00:00 - 2023-04-24 13:00:00 以此类推。 '
            >
              <DatePicker
                {...field}
                format='YYYY-MM-DD HH:mm'
                clearable
                showTime={{ format: 'HH:mm', minuteStep: 60 }}
              />
            </Form.Item>
          )}
        />
      </Form>
    </form>
  )
  const columnHtml = (
    <>
      <Button
        type='primary'
        style={{ marginLeft: '10px' }}
        onClick={() => {
          nodeRef.current.open(columns)
        }}
      >
        配置列
      </Button>
      <Table
        records={columns}
        columns={[
          {
            key: 'index',
            header: '序号',
            width: 48,
            render: (x, key, recordIndex) => recordIndex + 1,
            fixed: 'left'
          },
          {
            key: 'col_name',
            header: () => '列名',
            render: x => x.col_name,
            fixed: 'left'
          },
          {
            key: 'col_type',
            header: '类型',
            width: 80,
            render: (x, key, index) => {
              if (!x.col_type) return ''
              return colType.find(item => item.value === x.col_type).text
            }
          },
          {
            key: 'strategy',
            header: '策略',
            width: 160,
            render: (x, key, index) => {
              if (!x.strategy) return ''
              const text = colStrategy(text => text).find(
                item => item.value === x.strategy
              ).text
              return (
                <div>
                  <Text theme='primary'>{text}</Text>
                  {x.strategy === 'digital_random' && (
                    <div style={{ display: 'flex', flexFlow: 'column' }}>
                      <span>最大值:{x.max_value}</span>
                      <span>最大值:{x.min_value}</span>
                      <span>保留小数位数:{x.precision}</span>
                    </div>
                  )}
                  {(x.strategy === 'string_random' ||
                    x.strategy === 'string_random_mils') && (
                    <div style={{ display: 'flex', flexFlow: 'column' }}>
                      <span>最大长度:{x.max_length}</span>
                      <span>最小长度:{x.min_length}</span>
                      <span>是否含有字母:{x.has_letters ? '是' : '否'}</span>
                      <span>是否含有数字:{x.has_numbers ? '是' : '否'}</span>
                    </div>
                  )}
                  {x.strategy === 'enum_value_weight' && (
                    <div style={{ display: 'flex', flexFlow: 'column' }}>
                      <span>穷举权重:{x.enumValueWeight}</span>
                    </div>
                  )}
                  {x.strategy === 'string_assign' && (
                    <div style={{ display: 'flex', flexFlow: 'column' }}>
                      <span>指定值:{x.assignValue}</span>
                    </div>
                  )}
                  {x.strategy === 'auto_increment_rand' && (
                    <div style={{ display: 'flex', flexFlow: 'column' }}>
                      <span>最大长度:{x.max_length}</span>
                      <span>最小长度:{x.min_length}</span>
                    </div>
                  )}
                </div>
              )
            }
          },
          {
            key: 'null_percent',
            header: 'null值比例',
            width: 80,
            render: x => x.null_percent
          },
          {
            key: 'blank_percent',
            header: 'blank值比例',
            width: 85,
            render: x => x.blank_percent
          },
          {
            key: 'pre',
            header: '内容前缀',
            render: x => x.pre
          }
        ]}
      ></Table>
    </>
  )

  const dependHtml = (
    <div>
      <Button
        type='primary'
        style={{ marginLeft: '10px' }}
        onClick={() => {
          let dependInfo = dependInfoList.find(
            x => x.dependType === 'self_partition'
          )
          const infoconf = { ...info, columns }
          edgeRef.current.open(dependInfo, infoconf, infoconf)
        }}
      >
        配置自依赖
      </Button>
      <Table
        records={dependInfoList}
        recordKey='edgeId'
        columns={[
          {
            key: 'index',
            header: '序号',
            width: 48,
            render: (x, key, recordIndex) => recordIndex + 1
          },
          {
            key: 'dependType',
            header: '依赖类型',
            width: 100,
            render: x =>
              dependType.find(item => item.value === x.dependType)?.text
          },
          {
            key: 'circle',
            header: '获取方式',
            width: 100,
            render: x => (x.circle ? '顺序循环' : '随机')
          },
          {
            key: 'null_value_str',
            header: '空值标识',
            width: 100,
            render: x => {
              if (x.null_value_str === null) {
                return 'null'
              } else if (x.null_value_str === '') {
                return '空字符'
              }
              return x.null_value_str
            }
          },
          {
            key: 'percent',
            header: '依赖比例',
            width: 70
          },
          {
            header: '依赖来源',
            key: 'depend_table',
            width: 120,
            render: x => {
              if (x.dependType === 'outer_dataset') {
                return x.dataset_url
              }
              return x.depend_table
            }
          },
          {
            key: 'ops',
            header: '',
            width: 80,
            render: (x, key, index) => (
              <div style={{ display: 'flex' }}>
                <Button
                  type='link'
                  onClick={() => {
                    if (x.dependType === 'self_partition') {
                      const infoconf = { ...info, columns }
                      edgeRef.current.open(x, infoconf, infoconf)
                    } else {
                      onDependChange(x)
                    }
                  }}
                >
                  编辑
                </Button>
                <PopConfirm
                  message='确认删除?'
                  footer={close => (
                    <>
                      <Button
                        type='link'
                        onClick={() => {
                          setDependInfoList(prevRecords => {
                            prevRecords.splice(index, 1)
                            return prevRecords
                          })
                          close()
                          const dataset = {
                            ...info,
                            dependInfo: dependInfoList,
                            columns
                          }
                          onChange(dataset)
                        }}
                      >
                        删除
                      </Button>
                      <Button type='text' onClick={close}>
                        取消
                      </Button>
                    </>
                  )}
                  placement='top-start'
                >
                  <Icon style={{ cursor: 'pointer' }} type='close' />
                </PopConfirm>
              </div>
            )
          }
        ]}
      ></Table>
      <EdgeContent
        ref={edgeRef}
        setData={data => {
          let dependList = []
          if (dependInfoList.some(x => x.dependType === 'self_partition')) {
            dependList = dependInfoList.map(x => {
              if (x.dependType === 'self_partition') {
                return { ...x, ...data }
              }
              return x
            })
          } else {
            dependList = [...dependInfoList, data]
          }

          setDependInfoList(dependList)
          const dataset = { ...info, dependInfo: dependList, columns }
          onChange(dataset)
        }}
        onDelete={e => {
          if (!e) {
            // 移除dependType === 'self_partition'
            const dependList = dependInfoList.filter(
              x => x.dependType !== 'self_partition'
            )
            setDependInfoList(dependList)
            const dataset = { ...info, dependInfo: dependList, columns }
            onChange(dataset)
          }
        }}
      />
    </div>
  )

  return (
    <>
      <Drawer
        visible={visible}
        onClose={() => setVisible(false)}
        className='nodeContent'
        outerClickClosable={false}
        style={{
          width: 780
        }}
        title={
          <Tabs
            tabs={[
              { id: 'tableconf', label: 'Mock表配置' },
              { id: 'column', label: '列配置' },
              { id: 'depend', label: '依赖配置' }
            ]}
            activeId={activeId}
            onActive={({ id }) => setActiveId(id)}
            className='nodeContent-tabs'
          ></Tabs>
        }
        footer={
          activeId === 'tableconf' ? (
            <>
              <Button
                type='primary'
                loading={formState.isSubmitting}
                onClick={() => {
                  handleSubmit(onSubmit)()
                }}
              >
                保存
              </Button>
              <Button
                type='weak'
                style={{ marginLeft: '10px' }}
                onClick={() => setVisible(false)}
              >
                取消
              </Button>
            </>
          ) : null
        }
      >
        {activeId === 'tableconf' ? tableHtml : null}

        {activeId === 'column' ? columnHtml : null}

        {activeId === 'depend' ? dependHtml : null}
        <NodeCol
          ref={nodeRef}
          setData={data => {
            setColumns(data)
            const dataset = {
              ...info,
              columns: data,
              dependInfo: dependInfoList
            }
            onChange(dataset)
          }}
        ></NodeCol>
      </Drawer>
    </>
  )
}
export default NodeContent

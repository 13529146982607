import { useState, useEffect, useRef } from 'react'
import {
  Button,
  Drawer,
  Form,
  Input,
  InputNumber,
  Select,
  DatePicker,
  Tabs,
  TabPanel,
  Table,
  TagSelect
} from 'tea-component'
import { useForm, Controller } from 'react-hook-form'
import NodeCol from './nodeCol'
import { colType, colStrategy } from './defaultNode'

const NodeContent = ({ info, onChange }) => {
  const [visible, setVisible] = useState(false)
  const { control, handleSubmit, formState, reset, getValues } = useForm({
    mode: 'all'
  })

  useEffect(() => {
    const resetInfo = { ...info }
    if (info && info.nodeType === 'Kafka') {
      //把字info的key 中的.替换成_
      Object.keys(resetInfo).forEach(key => {
        if (key.includes('.')) {
          resetInfo[key.replace(/\./g, '_')] = resetInfo[key]
          delete resetInfo[key]
        }
      })
      setVisible(true)
      reset(resetInfo)
    } else {
      setVisible(false)
      reset({})
    }
  }, [info])

  async function onSubmit(values) {
    //把字values的key 中的_替换成.
    Object.keys(values).forEach(key => {
      if (key.includes('_')) {
        values[key.replace(/_/g, '.')] = values[key]
        delete values[key]
      }
    })
    const dataset = { ...values }

    onChange(dataset)
    console.log('dataset', dataset)
    setVisible(false)
  }

  function getStatus(meta) {
    if (!meta.isDirty && !formState.isSubmitted) {
      return null
    }
    return meta.invalid ? 'error' : 'success'
  }

  const tableHtml = (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Form>
        <Controller
          name='acks'
          control={control}
          rules={{
            required: '请输入acks'
          }}
          render={({ field, fieldState }) => (
            <Form.Item
              label='acks'
              status={getStatus(fieldState)}
              message={fieldState.error?.message}
            >
              <Input
                {...field}
                autoComplete='off'
                maxLength={200}
                style={{ width: '100%' }}
                placeholder='请输入acks'
              />
            </Form.Item>
          )}
        />

        <Controller
          name='batch_size'
          control={control}
          rules={{
            required: '请输入batch.size'
          }}
          render={({ field, fieldState }) => (
            <Form.Item
              label='batch.size'
              status={getStatus(fieldState)}
              message={fieldState.error?.message}
            >
              <Input
                {...field}
                autoComplete='off'
                maxLength={200}
                style={{ width: '100%' }}
                placeholder='请输入batch.size'
              />
            </Form.Item>
          )}
        />
        <Controller
          name='bootstrap_servers'
          control={control}
          rules={{
            required: '请输入bootstrap.servers'
          }}
          render={({ field, fieldState }) => (
            <Form.Item
              label='bootstrap.servers'
              status={getStatus(fieldState)}
              message={fieldState.error?.message}
            >
              <Input
                {...field}
                autoComplete='off'
                maxLength={200}
                style={{ width: '100%' }}
                placeholder='请输入bootstrap.servers'
              />
            </Form.Item>
          )}
        />
        <Controller
          name='buffer_memory'
          control={control}
          rules={{
            required: '请输入buffer.memory'
          }}
          render={({ field, fieldState }) => (
            <Form.Item
              label='buffer.memory'
              status={getStatus(fieldState)}
              message={fieldState.error?.message}
            >
              <Input
                {...field}
                autoComplete='off'
                maxLength={200}
                style={{ width: '100%' }}
                placeholder='请输入buffer.memory'
              />
            </Form.Item>
          )}
        />
        <Controller
          name='kafkaPwd'
          control={control}
          rules={{
            required: '请输入kafkaPwd'
          }}
          render={({ field, fieldState }) => (
            <Form.Item
              label='kafkaPwd'
              status={getStatus(fieldState)}
              message={fieldState.error?.message}
            >
              <Input
                {...field}
                autoComplete='off'
                maxLength={200}
                style={{ width: '100%' }}
                placeholder='请输入kafkaPwd'
              />
            </Form.Item>
          )}
        />
        <Controller
          name='kafkaUserName'
          control={control}
          rules={{
            required: '请输入kafkaUserName'
          }}
          render={({ field, fieldState }) => (
            <Form.Item
              label='kafkaUserName'
              status={getStatus(fieldState)}
              message={fieldState.error?.message}
            >
              <Input
                {...field}
                autoComplete='off'
                maxLength={200}
                style={{ width: '100%' }}
                placeholder='请输入kafkaUserName'
              />
            </Form.Item>
          )}
        />
        <Controller
          name='key_serializer'
          control={control}
          rules={{
            required: '请输入key.serializer'
          }}
          render={({ field, fieldState }) => (
            <Form.Item
              label='key.serializer'
              status={getStatus(fieldState)}
              message={fieldState.error?.message}
            >
              <Input
                {...field}
                autoComplete='off'
                maxLength={200}
                style={{ width: '100%' }}
                placeholder='请输入key.serializer'
              />
            </Form.Item>
          )}
        />
        <Controller
          name='linger_ms'
          control={control}
          rules={{
            required: '请输入linger.ms'
          }}
          render={({ field, fieldState }) => (
            <Form.Item
              label='linger.ms'
              status={getStatus(fieldState)}
              message={fieldState.error?.message}
            >
              <Input
                {...field}
                autoComplete='off'
                maxLength={200}
                style={{ width: '100%' }}
                placeholder='请输入linger.ms'
              />
            </Form.Item>
          )}
        />
        <Controller
          name='retries'
          control={control}
          rules={{
            required: '请输入retries'
          }}
          render={({ field, fieldState }) => (
            <Form.Item
              label='retries'
              status={getStatus(fieldState)}
              message={fieldState.error?.message}
            >
              <Input
                {...field}
                autoComplete='off'
                maxLength={200}
                style={{ width: '100%' }}
                placeholder='请输入retries'
              />
            </Form.Item>
          )}
        />
        <Controller
          name='value_serializer'
          control={control}
          rules={{
            required: '请输入value.serializer'
          }}
          render={({ field, fieldState }) => (
            <Form.Item
              label='value.serializer'
              status={getStatus(fieldState)}
              message={fieldState.error?.message}
            >
              <Input
                {...field}
                autoComplete='off'
                maxLength={200}
                style={{ width: '100%' }}
                placeholder='请输入value.serializer'
              />
            </Form.Item>
          )}
        />
      </Form>
    </form>
  )

  const dependHtml = <></>

  const [activeId, setActiveId] = useState('tableconf')
  return (
    <>
      <Drawer
        visible={visible}
        onClose={() => setVisible(false)}
        className='nodeContent'
        outerClickClosable={false}
        style={{
          width: 600
        }}
        title={
          <Tabs
            tabs={[
              { id: 'tableconf', label: '数据集配置' }
              // { id: 'depend', label: '依赖配置' }
            ]}
            activeId={activeId}
            onActive={({ id }) => setActiveId(id)}
            className='nodeContent-tabs'
          ></Tabs>
        }
        footer={
          activeId === 'tableconf' ? (
            <>
              <Button
                type='primary'
                loading={formState.isSubmitting}
                onClick={() => {
                  handleSubmit(onSubmit)()
                }}
              >
                保存
              </Button>
              <Button
                type='weak'
                style={{ marginLeft: '10px' }}
                onClick={() => setVisible(false)}
              >
                取消
              </Button>
            </>
          ) : null
        }
      >
        {activeId === 'tableconf' ? tableHtml : null}
      </Drawer>
    </>
  )
}
export default NodeContent

import { useState, useRef, useCallback } from 'react'
import ReactFlow, {
  ReactFlowProvider,
  addEdge,
  ReactFlowInstance,
  Connection,
  Edge,
  Node,
  useNodesState,
  useEdgesState,
  Controls,
  MarkerType
} from 'react-flow-renderer'

import Sidebar from './sidebar'
import MockNode from './mockNode'
import UpdateNode from './nodeContent'
import GroupA from './groupA'
import KafkaNode from './kafkaNode'
import EdgeContent from './edgeContent'
import DataSetNode from './dataSetNode.jsx'
import JsonModel from './jsonModel.jsx'
import {
  mockData,
  defaultDataSet,
  groupANode,
  defaultKafka
} from './defaultNode'
import DataSetContent from './dataSetContent.jsx'
import KafkaContent from './kafkaContent.jsx'
// import CustomEdge from './customEdge.jsx'
import { Modal, message } from 'tea-component'
import { v4 as uuidv4 } from 'uuid'
import './index.scss'
import moment from 'moment'

const nodeTypes = {
  Mock: MockNode,
  GroupA: GroupA,
  Kafka: KafkaNode,
  DataSet: DataSetNode
}
const edgeTypes = {
  // CustomEdge: CustomEdge
}

const DnDFlow = () => {
  const initialNodes = [
    {
      ...groupANode,
      data: {
        label: '数据组件',
        onHide: e => {
          hideNodes(e)
        },
        hidden: false,
        height: 500,
        width: 500
      }
    }
  ]
  const jsonModelRef = useRef(null)
  const reactFlowWrapper = useRef(null)
  const [reactFlowInstance, setReactFlowInstance] = useState()
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes)
  const [edges, setEdges, onEdgesChange] = useEdgesState([])

  const [nodeInfo, setNodeInfo] = useState(null)
  const edgeRef = useRef(null)

  const onConnect = params => {
    //禁止互相连接
    const connectedEdges = edges.filter(
      edge => edge.source === params.target && edge.target === params.source
    )
    if (connectedEdges.length > 0) {
      console.log('禁止连接已经相连的节点')
      return
    }
    const { source, target } = params
    const nodesA = reactFlowInstance.getNodes()
    const sourceNode = nodesA.find(n => n.id === source)
    const targetNode = nodesA.find(n => n.id === target)

    const DataNodes = ['DataSet', 'Mock']

    if (
      DataNodes.includes(sourceNode.type) &&
      !DataNodes.includes(targetNode.type)
    ) {
      console.log('禁止数据组件连接其他组件')
      return
    }
    if (
      DataNodes.includes(targetNode.type) &&
      !DataNodes.includes(sourceNode.type)
    ) {
      console.log('禁止数据组件连接其他组件')
      return
    }

    let edgeTemp = {}
    if (DataNodes.includes(sourceNode.type)) {
      edgeTemp = {
        label: '依赖',
        style: { stroke: 'red' }
      }
    }
    setEdges(es =>
      addEdge(
        {
          ...params,
          zIndex: 1,
          ...edgeTemp,
          markerEnd: { type: MarkerType.ArrowClosed, width: 20, height: 20 }
        },
        es
      )
    )
  }
  const onInit = rfi => setReactFlowInstance(rfi)

  const onDragOver = useCallback(event => {
    event.preventDefault()
    event.dataTransfer.dropEffect = 'move'
  }, [])

  const onDrop = useCallback(
    event => {
      event.preventDefault()
      if (reactFlowInstance) {
        const type = event.dataTransfer.getData('application/reactflow')
        if (typeof type === 'undefined' || !type) {
          return
        }
        let position = null
        let newNode = {
          id: `${type}_${uuidv4()}`,
          type,
          data: { deleteNode: onChange }
        }
        const nodesA = reactFlowInstance.getNodes()
        if (type === 'Mock' || type === 'DataSet') {
          // 父组件折叠起来的时候不能新增
          const groupA = nodesA.find(n => n.id === 'GroupA1')
          if (groupA.data.hidden) {
            return
          }

          const groupADom = document
            .getElementById('groupA1')
            .getBoundingClientRect()
          const zoom = reactFlowInstance.getZoom()
          let x = (event.clientX - groupADom.left) / zoom - 60

          if (x < 10) {
            x = 10
          } else if (x + 120 > groupADom.width / zoom) {
            x = groupADom.width / zoom - 140
          }
          let y = (event.clientY - groupADom.top) / zoom - 20
          if (y < 50) {
            y = 50
          } else if (y + 50 > groupADom.height / zoom) {
            y = groupADom.height / zoom - 60
          }
          position = { x, y }
          newNode.parentNode = 'GroupA1'
          newNode.extent = 'parent'
        } else {
          const reactFlowBounds =
            reactFlowWrapper.current.getBoundingClientRect()
          position = reactFlowInstance.project({
            x: event.clientX - reactFlowBounds.left - 60,
            y: event.clientY - reactFlowBounds.top - 15
          })
        }
        const nodeField = { id: newNode.id, nodeType: type }
        if (type === 'Mock') {
          newNode.data.table = { ...mockData, ...nodeField }
        } else if (type === 'DataSet') {
          newNode.data.table = { ...defaultDataSet, ...nodeField }
        } else if (type === 'Kafka') {
          newNode.data.table = { ...defaultKafka, ...nodeField }
          if (nodesA.some(n => n.type === 'Kafka')) {
            message.error({
              content: '禁止新增多个Kafka组件'
            })
            return
          }
          setTimeout(
            () =>
              onConnect({
                source: 'GroupA1',
                target: newNode.id
              }),
            100
          )
        }
        newNode.position = position
        newNode.data.label = newNode.data.table?.name || type

        setNodes(nds => nds.concat(newNode))
      }
    },
    [reactFlowInstance]
  )

  const hideNodes = hidden => {
    //隐藏nodes
    const sourceNodes = []
    setNodes(nds =>
      nds.map(item => {
        if (item.id === 'GroupA1') {
          item.data.hidden = hidden
        } else if (item.parentNode === 'GroupA1') {
          item.hidden = hidden
          sourceNodes.push(item)
          return item
        }
        return item
      })
    )
    //隐藏edges
    setEdges(es =>
      es.map(item => {
        const sourceNode = sourceNodes.find(n => n.id === item.source) || {}
        if (['Mock', 'DataSet'].includes(sourceNode.type)) {
          item.hidden = hidden
          return item
        }
        return item
      })
    )
  }

  // 改变节点内容
  const changeNode = (val, isHde) => {
    if (isHde) {
      setNodeInfo(null)
    }
    const changeTarget = (columns, table_cols, depend_table_cols) => {
      const indexD = []
      depend_table_cols?.forEach((y, index) => {
        if (!columns.some(z => z.col_name === y)) {
          indexD.push(index)
        }
      })
      if (indexD.length > 0) {
        //删除table_cols 和depend_table_cols index的下标
        table_cols = table_cols.filter((_, index) => !indexD.includes(index))
        depend_table_cols = depend_table_cols.filter(
          (_, index) => !indexD.includes(index)
        )
      }

      return { table_cols, depend_table_cols }
    }

    setNodes(nds =>
      nds.map(item => {
        if (item.id === val.id) {
          if (item.type === 'Mock') {
            const { dependInfo } = val
            //移除已经更改或者删除的列依赖
            const { columns } = val
            const edgesSource = edges.filter(x => x.source === item.id)
            const nodesTarget = nodes.filter(x =>
              edgesSource.some(y => y.target === x.id)
            )
            //移除被依赖的字段
            nodesTarget.forEach(x => {
              x.data.table.dependInfo.forEach(y => {
                if (edgesSource.some(z => z.id === y.edgeId)) {
                  const res = changeTarget(
                    columns,
                    y.table_cols,
                    y.depend_table_cols
                  )
                  y.table_cols = res.table_cols
                  y.depend_table_cols = res.depend_table_cols
                  y.depend_table = val.name
                  y.dataset_format = val.out_format
                  y.col_split = val.col_split
                  y.col_kv_split = val.col_kv_split
                }
              })
            })

            const dependInfoArr = dependInfo?.map(x => {
              let { table_cols, depend_table_cols } = x
              //找出table_cols里面没有columns的所有下标
              const indexT = []
              table_cols?.forEach((y, index) => {
                if (!columns.some(z => z.col_name === y)) {
                  indexT.push(index)
                }
              })
              if (indexT.length > 0) {
                //删除table_cols 和depend_table_cols index的下标
                table_cols = table_cols.filter(
                  (_, index) => !indexT.includes(index)
                )
                depend_table_cols = depend_table_cols.filter(
                  (_, index) => !indexT.includes(index)
                )
                x.table_cols = table_cols
                x.depend_table_cols = depend_table_cols
              }

              if (x.dependType === 'self_partition') {
                if (val.name !== item.label) {
                  x.depend_table = val.name
                }
                const res = changeTarget(columns, table_cols, depend_table_cols)
                x.table_cols = res.table_cols
                x.depend_table_cols = res.depend_table_cols
                x.dataset_format = val.out_format
                x.col_split = val.col_split
                x.col_kv_split = val.col_kv_split
              }

              return { ...x }
            })

            item.data = {
              ...item.data,
              table: { ...val, dependInfo: dependInfoArr },
              label: val.name
            }
            if (nodeInfo?.id === val.id) {
              setNodeInfo(item.data.table)
            }
            //更新连接线依赖颜色

            const edgesArr = edges.filter(x => x.target === item.id)
            edgesArr.forEach(x => {
              let strokeStr = 'red'
              const dependInfoItem = dependInfo.find(y => y.edgeId === x.id)
              if (dependInfoItem && dependInfoItem.table_cols?.length > 0) {
                strokeStr = 'green'
              }
              changeEdge({
                ...x,
                style: {
                  stroke: strokeStr
                }
              })
            })
          } else if (item.type === 'DataSet') {
            const edgesSource = edges.filter(x => x.source === item.id)
            const nodesTarget = nodes.filter(x =>
              edgesSource.some(y => y.target === x.id)
            )
            //移除被依赖的字段
            nodesTarget.forEach(x => {
              x.data.table.dependInfo.forEach(y => {
                let { table_cols, need_keys_in_dataset, position_in_dataset } =
                  y

                if (edgesSource.some(z => z.id === y.edgeId)) {
                  if (
                    val.dataset_format !== y.dataset_format &&
                    (y.dataset_format === 'only_v' ||
                      val.dataset_format === 'only_v')
                  ) {
                    table_cols = []
                    need_keys_in_dataset = []
                    position_in_dataset = []
                  } else {
                    const indexD = []
                    if (val.dataset_format !== 'only_v') {
                      need_keys_in_dataset?.forEach((z, index) => {
                        if (!val.need_keys_in_dataset.includes(z)) {
                          indexD.push(index)
                        }
                      })
                    } else {
                      position_in_dataset?.forEach((z, index) => {
                        if (!val.position_in_dataset.includes(z)) {
                          indexD.push(index)
                        }
                      })
                    }
                    if (indexD.length > 0) {
                      //删除table_cols 和depend_table_cols index的下标
                      table_cols = table_cols?.filter(
                        (_, index) => !indexD.includes(index)
                      )
                      need_keys_in_dataset = need_keys_in_dataset?.filter(
                        (_, index) => !indexD.includes(index)
                      )
                      position_in_dataset = position_in_dataset?.filter(
                        (_, index) => !indexD.includes(index)
                      )
                    }
                  }

                  y.table_cols = table_cols
                  y.need_keys_in_dataset = need_keys_in_dataset
                  y.position_in_dataset = position_in_dataset
                  y.dataset_format = val.dataset_format
                  y.col_split = val.col_split
                  y.dataset_url = val.dataset_url
                  y.col_kv_split = val.col_kv_split
                }
              })
            })
            item.data = {
              ...item.data,
              table: { ...val },
              label: val.name
            }
          } else if (item.type === 'Kafka') {
            item.data = {
              ...item.data,
              table: { ...val }
            }
          }
        }
        return item
      })
    )
  }

  //修改连接内容
  const changeEdge = edge => {
    setEdges(es =>
      es.map(item => {
        if (item.id === edge.id) {
          return {
            ...item,
            ...edge
          }
        }
        return item
      })
    )
  }

  // 删除节点
  const onChange = nodeId => {
    // console.log(id)
    setNodes(nds => nds.filter(item => item.id !== nodeId))
    const edgeList = reactFlowInstance.getEdges()
    const esList = edgeList.filter(
      item => item.target === nodeId || item.source === nodeId
    )
    const edgeIds = esList.map(item => item.id)
    edgeIds.forEach(item => {
      onDeleteEdge(item)
    })
  }
  //删除连接线
  const onDeleteEdge = edgeId => {
    if (!edgeId) {
      return
    }
    const edgeList = reactFlowInstance.getEdges()
    const nodeList = reactFlowInstance.getNodes()
    const edge = edgeList.find(item => item.id === edgeId)
    const targetNode = nodeList.find(item => item.id === edge.target)
    if (targetNode && targetNode.type === 'Mock') {
      const { dependInfo } = targetNode.data.table
      const index = dependInfo?.findIndex(item => item.edgeId === edgeId)
      if (index > -1) {
        dependInfo.splice(index, 1)
        targetNode.data.table.dependInfo = dependInfo
      }
    }
    setEdges(es => es.filter(item => item.id !== edgeId))
  }

  //删除kafka连接
  const onDeleteEdgeKafka = edgeid => {
    setEdges(es => es.filter(item => item.id !== edgeid))
  }

  // 点击节点
  const onNodeClick = (e, node) => {
    console.log(node)
    setNodeInfo(null)
    if (['Mock', 'DataSet', 'Kafka'].includes(node.type)) {
      setNodeInfo({
        ...node.data.table
      })
    }
  }

  //点击连接线
  const onEdgeClick = (e, edge) => {
    console.log(edge)
    const sourceNode = nodes.find(item => item.id === edge.source) || {}
    if (!['Mock', 'DataSet'].includes(sourceNode.type)) {
      return
    }
    // const edgeInfo = JSON.parse(JSON.stringify(edge))
    const sourceData = sourceNode.data?.table
    const targetData = nodes.find(item => item.id === edge.target).data?.table

    const { dependInfo } = targetData
    const dependInfoRes = dependInfo.find(item => item.edgeId === edge.id) || {
      edgeId: edge.id
    }
    edgeRef.current.open(dependInfoRes, sourceData, targetData)
  }

  //弹框依赖点击
  const onDependChange = dependItem => {
    const edgeInfo = edges.find(item => item.id === dependItem.edgeId)
    if (edgeInfo) {
      onEdgeClick(null, edgeInfo)
    }
  }

  //导出json
  const exportJson = () => {
    const tableConfs = []
    const dataSetConfs = []
    let kafkaProducerConfig = {}
    let groupAConfs = {}
    nodes
      .map(item => {
        if (item.type === 'Mock') {
          let { partitionStart } = item.data.table
          if (partitionStart) {
            partitionStart = moment(partitionStart).format(
              'YYYY-MM-DD HH:mm:ss'
            )
          }
          tableConfs.push({
            ...item.data.table,
            partitionStart,
            nodePosition: item.position
          })
        } else if (item.type === 'DataSet') {
          dataSetConfs.push({ ...item.data.table, nodePosition: item.position })
        } else if (item.type === 'GroupA') {
          const { id, position, width, height } = item
          groupAConfs = { id, position, width, height }
        } else if (item.type === 'Kafka') {
          kafkaProducerConfig = {
            ...item.data.table,
            nodePosition: item.position
          }
        }
      })
      .filter(item => !!item)

    const edgeConfs = edges.map(item => {
      const { id, source, target, type, style, label } = item
      return { id, source, target, type, style, label }
    })
    const jsonData = JSON.stringify(
      {
        tableConfs,
        edgeConfs,
        dataSetConfs,
        groupAConfs,
        kafkaProducerConfig
      },
      null,
      2
    )
    jsonModelRef.current.open(jsonData)
  }

  //导入
  const importJson = async file => {
    const yes = await Modal.confirm({
      message: '确认导入文件？',
      description: '导入后会覆盖当前页面数据，是否继续？',
      okText: '确认',
      cancelText: '取消'
    })
    if (!yes) {
      return
    }
    setNodes([])
    setEdges([])
    const reader = new FileReader()
    reader.onload = function (e) {
      try {
        const jsonData = JSON.parse(e.target.result)
        console.log(jsonData)
        const {
          tableConfs,
          edgeConfs,
          dataSetConfs,
          groupAConfs,
          kafkaProducerConfig
        } = jsonData
        const { position, width, height } = groupAConfs
        const groupANode1 = {
          ...groupANode,
          position,
          id: 'GroupA1',
          type: 'GroupA',
          data: {
            label: '数据组件',
            onHide: e => {
              hideNodes(e)
            },
            hidden: false,
            height: height,
            width: width
          }
        }
        const tablesNode = [...tableConfs, ...dataSetConfs].map(table => {
          const { nodePosition, id, name, nodeType } = table
          let { partitionStart } = table
          if (partitionStart) {
            partitionStart = moment(partitionStart)
          }
          return {
            id,
            type: nodeType,
            position: nodePosition,
            parentNode: 'GroupA1',
            extent: 'parent',
            data: {
              label: name,
              deleteNode: onChange,
              table: { ...table, partitionStart }
            }
          }
        })

        const kafkaNodes = []
        if (kafkaProducerConfig && kafkaProducerConfig.id) {
          const { nodePosition, id, table } = kafkaProducerConfig
          const kafkaNode = {
            id,
            type: 'Kafka',
            position: nodePosition,
            data: {
              label: `Kafka`,
              deleteNode: onChange,
              table: kafkaProducerConfig
            }
          }
          kafkaNodes.push(kafkaNode)
        }

        const edgesArr = edgeConfs.map(edge => {
          let data = {}
          // if (edge.type === 'CustomEdge') {
          //   data = {
          //     onDelete: onDeleteEdgeKafka
          //   }
          // }
          return {
            ...edge,
            zIndex: 1,
            data,
            markerEnd: { type: MarkerType.ArrowClosed, width: 20, height: 20 }
          }
        })

        setNodes([groupANode1, ...tablesNode, ...kafkaNodes])
        setEdges(edgesArr)
      } catch (error) {
        console.error('无法解析 JSON 文件：', error)
      }
    }
    reader.readAsText(file)
  }

  return (
    <div className='dndflow'>
      <ReactFlowProvider initialNodes={initialNodes} initialEdges={[]}>
        <Sidebar exportjson={exportJson} importjson={importJson} />
        <JsonModel ref={jsonModelRef} />
        <div className='wrapper' ref={reactFlowWrapper}>
          <ReactFlow
            nodes={nodes}
            edges={edges}
            nodeTypes={nodeTypes}
            edgeTypes={edgeTypes}
            onEdgesChange={onEdgesChange}
            onNodesChange={onNodesChange}
            onConnect={onConnect}
            onInit={onInit}
            onDrop={onDrop}
            onDragOver={onDragOver}
            onNodeClick={onNodeClick}
            onEdgeClick={onEdgeClick}
            onNodesDelete={onChange}
            deleteKeyCode={[]}
            onEd
          >
            <UpdateNode
              info={nodeInfo}
              onChange={changeNode}
              onDependChange={onDependChange}
            />
            <DataSetContent info={nodeInfo} onChange={changeNode} />
            <KafkaContent info={nodeInfo} onChange={changeNode} />
            <EdgeContent
              ref={edgeRef}
              setData={data => {
                let dependList = []
                const edge = edges.find(item => item.id === data.edgeId)
                const node = nodes.find(item => item.id === edge.target)
                const { dependInfo } = node.data.table || []
                if (dependInfo.some(x => x.edgeId === data.edgeId)) {
                  dependList = dependInfo.map(x => {
                    if (x.edgeId === data.edgeId) {
                      return { ...x, ...data }
                    }
                    return x
                  })
                } else {
                  dependList = [...dependInfo, data]
                }
                changeNode({ ...node.data.table, dependInfo: dependList })

                if (nodeInfo?.id === node.id) {
                  setNodeInfo(node.data.table)
                }
              }}
              onDelete={onDeleteEdge}
            />
            <Controls />
          </ReactFlow>
        </div>
      </ReactFlowProvider>
    </div>
  )
}

export default DnDFlow

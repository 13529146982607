import { useState, useEffect, useRef } from 'react'
import {
  Button,
  Drawer,
  Form,
  Input,
  InputNumber,
  Select,
  DatePicker,
  Tabs,
  TabPanel,
  Table,
  TagSelect
} from 'tea-component'
import { useForm, Controller } from 'react-hook-form'
import NodeCol from './nodeCol'
import { colType, colStrategy } from './defaultNode'

const NodeContent = ({ info, onChange }) => {
  const [visible, setVisible] = useState(false)
  const { control, handleSubmit, formState, reset, getValues } = useForm({
    mode: 'all'
  })

  useEffect(() => {
    const resetInfo = { ...info }
    if (info && info.nodeType === 'DataSet') {
      setVisible(true)
      reset(resetInfo)
    } else {
      setVisible(false)
      reset({})
    }
  }, [info])

  async function onSubmit(values) {
    const dataset = { ...values }
    const { position_in_dataset } = values
    if (position_in_dataset) {
      dataset.position_in_dataset = position_in_dataset?.map(item =>
        Number(item)
      )
    }
    onChange(dataset)
    console.log('dataset', dataset)
    setVisible(false)
  }

  function getStatus(meta) {
    if (!meta.isDirty && !formState.isSubmitted) {
      return null
    }
    return meta.invalid ? 'error' : 'success'
  }

  const tableHtml = (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Form>
        <Controller
          name='name'
          control={control}
          rules={{
            required: '请输入名称'
          }}
          render={({ field, fieldState }) => (
            <Form.Item
              label='数据集名称'
              status={getStatus(fieldState)}
              message={fieldState.error?.message}
            >
              <Input
                {...field}
                autoComplete='off'
                maxLength={20}
                placeholder='请输入名称'
              />
            </Form.Item>
          )}
        />
        <Controller
          name='dataset_url'
          control={control}
          rules={{
            required: '请输入路径'
          }}
          render={({ field, fieldState }) => (
            <Form.Item
              label='数据集路径'
              status={getStatus(fieldState)}
              message={fieldState.error?.message}
            >
              <Input
                {...field}
                autoComplete='off'
                maxLength={20}
                placeholder='请输入路径'
              />
            </Form.Item>
          )}
        />

        <Controller
          name='dataset_format'
          control={control}
          render={({ field, fieldState }) => (
            <Form.Item label='数据集格式' extra='更改此项会清空表依赖字段'>
              <Select
                {...field}
                appearance='button'
                options={[
                  { text: 'json', value: 'json' },
                  { text: 'value', value: 'only_v' },
                  { text: 'key加value', value: 'k_and_v' }
                ]}
              />
            </Form.Item>
          )}
        />

        <Controller
          name='col_split'
          control={control}
          rules={{
            required: '请输入分隔符'
          }}
          render={({ field, fieldState }) => (
            <Form.Item
              label='分隔符'
              extra='列之间的分割符号，默认 `'
              status={getStatus(fieldState)}
              message={fieldState.error?.message}
            >
              <Input {...field} maxLength={1} />
            </Form.Item>
          )}
        />

        <Controller
          name='col_kv_split'
          control={control}
          rules={{
            required: '请输入分隔符'
          }}
          render={({ field, fieldState }) => (
            <Form.Item
              label='value和weight分隔符'
              status={getStatus(fieldState)}
              message={fieldState.error?.message}
            >
              <Input {...field} maxLength={1} placeholder='' />
            </Form.Item>
          )}
        />
        <Controller
          name='need_keys_in_dataset'
          control={control}
          render={({ field, fieldState }) => {
            return (
              <Form.Item
                label='列数据字段'
                extra='需要被依赖的字段,当数据集格式为json和key加value时依赖配置需要此项'
              >
                <TagSelect
                  {...field}
                  placeholder='字段名'
                  tips='按回车键确认'
                />
              </Form.Item>
            )
          }}
        />

        <Controller
          name='position_in_dataset'
          control={control}
          rules={{
            validate: value => {
              //判断是否是数字
              const reg = /^[0-9]*$/
              let isReg = true
              value &&
                value.forEach(item => {
                  if (!reg.test(item)) {
                    isReg = false
                  }
                })
              return isReg ? true : '只支持数字'
            }
          }}
          render={({ field, fieldState }) => {
            return (
              <Form.Item
                label='列数据位置'
                extra='当数据集格式为value时需要此项；
                  定义取数据集中哪几列(最小列的的编号为1)。 例如：数据分列后，需要取第一列和第五列数据，那么值就为[1,5]'
                status={getStatus(fieldState)}
                message={fieldState.error?.message}
              >
                <TagSelect
                  {...field}
                  placeholder='列位置'
                  tips='按回车键确认'
                />
              </Form.Item>
            )
          }}
        />
      </Form>
    </form>
  )

  const dependHtml = <></>

  const [activeId, setActiveId] = useState('tableconf')
  return (
    <>
      <Drawer
        visible={visible}
        onClose={() => setVisible(false)}
        className='nodeContent'
        outerClickClosable={false}
        style={{
          width: 600
        }}
        title={
          <Tabs
            tabs={[
              { id: 'tableconf', label: '数据集配置' }
              // { id: 'depend', label: '依赖配置' }
            ]}
            activeId={activeId}
            onActive={({ id }) => setActiveId(id)}
            className='nodeContent-tabs'
          ></Tabs>
        }
        footer={
          activeId === 'tableconf' ? (
            <>
              <Button
                type='primary'
                loading={formState.isSubmitting}
                onClick={() => {
                  handleSubmit(onSubmit)()
                }}
              >
                保存
              </Button>
              <Button
                type='weak'
                style={{ marginLeft: '10px' }}
                onClick={() => setVisible(false)}
              >
                取消
              </Button>
            </>
          ) : null
        }
      >
        {activeId === 'tableconf' ? tableHtml : null}

        {/* {activeId === 'depend' ? dependHtml : null} */}
      </Drawer>
    </>
  )
}
export default NodeContent

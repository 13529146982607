import React, { memo } from 'react'
import { Icon, Modal } from 'tea-component'

import { Handle, Position } from 'react-flow-renderer'

export default memo(({ data, id, isConnectable }) => {
  // console.log(1, data)
  if (data.isHide) {
    return <></>
  }
  return (
    <div>
      <div className='nodeSelContent' style={data.style}>
        <div className='nodelabel'>{data.label}</div>
        <div className='close'>
          <Icon
            type='close'
            onClick={async e => {
              e.stopPropagation()
              const yes = await Modal.confirm({
                message: '确认删除当前所选实例？',
                description:
                  '删除后，该实例下的所有配置将会被清空，且无法恢复。',
                okText: '删除',
                cancelText: '取消'
              })
              yes && data.deleteNode(id)
            }}
            className='icon-close'
          />
        </div>
      </div>
      {/* <Handle
        type='target'
        position={Position.Left}
        className='my_handle'
        onConnect={params => console.log('handle onConnect', params)}
        isConnectable={isConnectable}
      /> */}
      <Handle
        type='source'
        position={Position.Right}
        id='a'
        isConnectable={isConnectable}
      />
    </div>
  )
})

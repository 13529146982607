import {
  useState,
  useEffect,
  useCallback,
  useRef,
  forwardRef,
  useImperativeHandle
} from 'react'
import {
  Modal,
  Button,
  Table,
  Input,
  Text,
  Bubble,
  Icon,
  Select,
  InputNumber,
  PopConfirm,
  Switch,
  Form
} from 'tea-component'
import NodeColJson from './nodeColJson'
import { defaultCol, colType, colStrategy } from './defaultNode'

const { draggable, scrollable } = Table.addons

let id = 0
const getId = () => id++

export default forwardRef(({ setData }, ref) => {
  const [show, setShow] = useState(false)

  useImperativeHandle(ref, () => ({
    open: dataList => {
      if (dataList && dataList.length > 0) {
        id = dataList.length
        setRecords(dataList)
      } else {
        setRecords([defaultRecords])
      }
      setShow(true)
    }
  }))

  const close = () => {
    setShow(false)
  }

  const defaultRecords = {
    ...defaultCol,
    order: getId()
  }

  const tips = (text, tip) => {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Text>{text}</Text>
        <Bubble arrowPointAtCenter placement='top-end' content={tip}>
          <Icon type='info' size={12} style={{ marginLeft: 5 }} />
        </Bubble>
      </div>
    )
  }

  const [records, setRecords] = useState([defaultRecords])
  const nodeJsonRef = useRef([])

  const [errorMsg, setErrorMsg] = useState([])

  const handleAddOrUpdate = newItem => {
    const existingItemIndex = errorMsg.findIndex(item => item.id === newItem.id)
    if (existingItemIndex !== -1) {
      const updatedArray = [...errorMsg]
      updatedArray[existingItemIndex] = newItem
      setErrorMsg(updatedArray)
    } else {
      setErrorMsg([...errorMsg, newItem])
    }
  }

  //列名验证
  const verName = (itemid, value) => {
    const msgItem = {
      id: itemid,
      msg: '请输入列名'
    }
    if (value === '') {
      handleAddOrUpdate(msgItem)
      return false
    }
    const chineseRegex = /[\u4e00-\u9fa5]/
    if (chineseRegex.test(value)) {
      msgItem.msg = '列名不能为中文'
      handleAddOrUpdate(msgItem)
      return false
    }
    //验证存在两条
    if (
      records.findIndex(
        item => item.col_name === value && `${item.order}_col_name` !== itemid
      ) !== -1
    ) {
      msgItem.msg = '列名不能重复'
      handleAddOrUpdate(msgItem)
      return false
    }

    setErrorMsg(msg => msg.filter(item => item.id !== itemid))
    return true
  }

  //验证策略不能为空
  const verStrategy = (itemid, value) => {
    const msgItem = {
      id: itemid,
      msg: '请选择策略'
    }
    if (value === '') {
      handleAddOrUpdate(msgItem)
      return false
    }
    setErrorMsg(msg => msg.filter(item => item.id !== itemid))
    return true
  }

  const handleSave = useCallback(
    record => {
      setRecords(prevRecords => {
        const newRecords = [...prevRecords]
        const index = newRecords.findIndex(i => record.order === i.order)
        newRecords.splice(index, 1, {
          ...prevRecords[index],
          ...record
        })
        return newRecords
      })
    },
    [setRecords]
  )

  //解决表格拖拽和输入框选择的冲突
  const trDraggable = (e, drag) => {
    const trNode = e.currentTarget.parentElement?.parentElement?.parentElement
    trNode && trNode.setAttribute('draggable', drag)
  }

  return (
    <Modal
      visible={show}
      caption='列配置（修改列或删除列会同步删除依赖配置的关联）'
      onClose={close}
      size={1200}
    >
      <Modal.Body>
        <Table
          records={records}
          recordKey='order'
          columns={[
            {
              key: 'index',
              header: '序号',
              width: 40,
              render: (x, key, recordIndex) => recordIndex + 1,
              fixed: 'left'
            },
            {
              key: 'col_name',
              header: () => tips('列名', '不能为中文'),
              render: x => {
                const itemid = `${x.order}_col_name`
                let msgItem = errorMsg.find(item => item.id === itemid)
                return (
                  <Bubble content={msgItem?.msg} error>
                    <Input
                      style={{
                        borderColor: msgItem?.msg ? 'red' : ''
                      }}
                      onFocus={e => trDraggable(e, false)}
                      onBlur={e => trDraggable(e, true)}
                      onChange={value => {
                        verName(itemid, value)
                        handleSave({
                          ...x,
                          col_name: value
                        })
                      }}
                      defaultValue={String(x.col_name)}
                    />
                  </Bubble>
                )
              },
              fixed: 'left'
            },
            {
              key: 'col_type',
              header: '类型',
              width: 150,
              render: (x, key, index) => (
                <>
                  <Select
                    defaultValue={x.col_type}
                    onChange={value => {
                      if (value === 'TYPE_JSON') {
                        x.strategy = ''
                      }
                      handleSave({
                        ...x,
                        col_type: value
                      })
                    }}
                    appearance='button'
                    options={colType}
                  />
                  {x.col_type === 'TYPE_JSON' && (
                    <>
                      <Button
                        onClick={() => {
                          nodeJsonRef.current[index].open(
                            x.json_col_def,
                            x.col_name
                          )
                        }}
                        style={{ marginLeft: '10px' }}
                        type='link'
                      >
                        配置
                      </Button>
                      <NodeColJson
                        ref={el => (nodeJsonRef.current[index] = el)}
                        setData={data => {
                          handleSave({
                            ...x,
                            json_col_def: data
                          })
                        }}
                      />
                    </>
                  )}
                </>
              )
            },
            {
              key: 'strategy',
              header: '策略',
              width: 280,
              render: x => {
                if (x.col_type === 'TYPE_JSON') {
                  return <></>
                }
                const itemid = `${x.order}_strategy`
                let msgItem = errorMsg.find(item => item.id === itemid)
                return (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Bubble content={msgItem?.msg} error>
                      <Select
                        style={{ border: msgItem?.msg ? '1px solid red' : '' }}
                        defaultValue={x.strategy}
                        searchable
                        onChange={value => {
                          verStrategy(itemid, value)
                          handleSave({
                            ...x,
                            strategy: value
                          })
                        }}
                        appearance='button'
                        options={colStrategy(tips)}
                      />
                    </Bubble>
                    {x.strategy === 'digital_random' && (
                      <div style={{ display: 'flex' }}>
                        <PopConfirm
                          className='nodeCol-pop'
                          message={
                            <Form>
                              <Form.Item label='最大值'>
                                <InputNumber
                                  defaultValue={x.max_value}
                                  step={0.1}
                                  inputProps={{ style: { width: '66px' } }}
                                  precision={5}
                                  onChange={value => {
                                    handleSave({
                                      ...x,
                                      max_value: value
                                    })
                                  }}
                                />
                              </Form.Item>
                              <Form.Item label='最小值'>
                                <InputNumber
                                  defaultValue={x.min_value}
                                  step={0.1}
                                  inputProps={{ style: { width: '66px' } }}
                                  precision={5}
                                  onChange={value => {
                                    handleSave({
                                      ...x,
                                      min_value: value
                                    })
                                  }}
                                />
                              </Form.Item>
                              <Form.Item label='保留小数位数'>
                                <div style={{ display: 'flex' }}>
                                  <InputNumber
                                    defaultValue={x.precision}
                                    step={1}
                                    min={-1}
                                    inputProps={{ style: { width: '50px' } }}
                                    onChange={value => {
                                      handleSave({
                                        ...x,
                                        precision: value
                                      })
                                    }}
                                  />
                                  {tips(
                                    '',
                                    '策略生成的值的小数点保留位数。默认为-1，表示不处理。0 ：生成的为数字为整形，1 ：生成的为数字为保留1位小数，2 ：生成的为数字为保留2位小数，依次类推。'
                                  )}
                                </div>
                              </Form.Item>
                            </Form>
                          }
                          placement='right'
                          footer={close => (
                            <>
                              <Button
                                type='link'
                                onClick={() => {
                                  close()
                                }}
                              >
                                确认
                              </Button>
                            </>
                          )}
                        >
                          <Button style={{ marginLeft: '10px' }} type='link'>
                            配置
                          </Button>
                        </PopConfirm>
                        <div style={{ display: 'flex', flexFlow: 'column' }}>
                          <span>最大值:{x.max_value}</span>
                          <span>最大值:{x.min_value}</span>
                          <span>保留小数位数:{x.precision}</span>
                        </div>
                      </div>
                    )}

                    {(x.strategy === 'string_random' ||
                      x.strategy === 'string_random_mils') && (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <PopConfirm
                          className='nodeCol-pop'
                          message={
                            <Form>
                              <Form.Item label='最大长度'>
                                <InputNumber
                                  defaultValue={x.max_length}
                                  step={1}
                                  min={1}
                                  inputProps={{ style: { width: '66px' } }}
                                  onChange={value => {
                                    handleSave({
                                      ...x,
                                      max_length: value
                                    })
                                  }}
                                />
                              </Form.Item>
                              <Form.Item label='最小长度'>
                                <InputNumber
                                  defaultValue={x.min_length}
                                  step={1}
                                  min={1}
                                  inputProps={{ style: { width: '66px' } }}
                                  onChange={value => {
                                    handleSave({
                                      ...x,
                                      min_length: value
                                    })
                                  }}
                                />
                              </Form.Item>
                              <Form.Item label='是否含有字母'>
                                <Switch
                                  defaultValue={x.has_letters}
                                  onChange={value => {
                                    handleSave({
                                      ...x,
                                      has_letters: value
                                    })
                                  }}
                                ></Switch>
                              </Form.Item>
                              <Form.Item label='是否含有数字'>
                                <Switch
                                  defaultValue={x.has_numbers}
                                  onChange={value => {
                                    handleSave({
                                      ...x,
                                      has_numbers: value
                                    })
                                  }}
                                ></Switch>
                              </Form.Item>
                            </Form>
                          }
                          placement='right'
                          footer={close => (
                            <>
                              <Button
                                type='link'
                                onClick={() => {
                                  close()
                                }}
                              >
                                确认
                              </Button>
                            </>
                          )}
                        >
                          <Button style={{ marginLeft: '10px' }} type='link'>
                            配置
                          </Button>
                        </PopConfirm>

                        <div style={{ display: 'flex', flexFlow: 'column' }}>
                          <span>最大长度:{x.max_length}</span>
                          <span>最小长度:{x.min_length}</span>
                          <span>
                            是否含有字母:{x.has_letters ? '是' : '否'}
                          </span>
                          <span>
                            是否含有数字:{x.has_numbers ? '是' : '否'}
                          </span>
                        </div>
                      </div>
                    )}

                    {x.strategy === 'enum_value_weight' && (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <PopConfirm
                          className='nodeCol-pop'
                          message={
                            <Form>
                              <Form.Item label='穷举权重'>
                                <div style={{ display: 'flex' }}>
                                  <Input
                                    defaultValue={x.enumValueWeight}
                                    style={{ width: 150 }}
                                    onChange={value => {
                                      handleSave({
                                        ...x,
                                        enumValueWeight: value
                                      })
                                    }}
                                  />
                                  {tips(
                                    '',
                                    '策略生成的字符串的值能够穷举。控制所有能够穷举的的值及对应的权重。有三种配置方式: \
                                    1、有权重情况 ： guangzhou:50,wuhuan:30 。生成的值为guangzhou和wuhuan。他们出现的概率比为50:30 \
                                    2、无权重情况 ： guangzhou,wuhang 。生成的值为guangzhou和wuhuan。他们出现的概率比为1:1 .\
                                    3、自动生成穷举   ：使用build:数字。例如 build:100 build为固定标识写法，100为生成的100个枚举值。生成的 100位的 前面补数两个00 ，如001 ，002，,003 ，…… ，100\
                                    '
                                  )}
                                </div>
                              </Form.Item>
                            </Form>
                          }
                          placement='right'
                          footer={close => (
                            <>
                              <Button
                                type='link'
                                onClick={() => {
                                  close()
                                }}
                              >
                                确认
                              </Button>
                            </>
                          )}
                        >
                          <Button style={{ marginLeft: '10px' }} type='link'>
                            配置
                          </Button>
                        </PopConfirm>
                        <div style={{ display: 'flex', flexFlow: 'column' }}>
                          <span>值:{x.enumValueWeight}</span>
                        </div>
                      </div>
                    )}

                    {x.strategy === 'string_assign' && (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <PopConfirm
                          className='nodeCol-pop'
                          message={
                            <Form>
                              <Form.Item label='值'>
                                <div style={{ display: 'flex' }}>
                                  <Input
                                    defaultValue={x.assignValue}
                                    style={{ width: 150 }}
                                    onChange={value => {
                                      handleSave({
                                        ...x,
                                        assignValue: value
                                      })
                                    }}
                                  />
                                </div>
                              </Form.Item>
                            </Form>
                          }
                          placement='right'
                          footer={close => (
                            <>
                              <Button
                                type='link'
                                onClick={() => {
                                  close()
                                }}
                              >
                                确认
                              </Button>
                            </>
                          )}
                        >
                          <Button style={{ marginLeft: '10px' }} type='link'>
                            配置
                          </Button>
                        </PopConfirm>
                        <div style={{ display: 'flex', flexFlow: 'column' }}>
                          <span>值:{x.assignValue}</span>
                        </div>
                      </div>
                    )}

                    {x.strategy === 'auto_increment_rand' && (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <PopConfirm
                          className='nodeCol-pop'
                          message={
                            <Form>
                              <Form.Item label='最大长度'>
                                <InputNumber
                                  defaultValue={x.max_length}
                                  step={1}
                                  min={1}
                                  inputProps={{ style: { width: '66px' } }}
                                  onChange={value => {
                                    handleSave({
                                      ...x,
                                      max_length: value
                                    })
                                  }}
                                />
                              </Form.Item>
                              <Form.Item label='最小长度'>
                                <InputNumber
                                  defaultValue={x.min_length}
                                  step={1}
                                  min={1}
                                  inputProps={{ style: { width: '66px' } }}
                                  onChange={value => {
                                    handleSave({
                                      ...x,
                                      min_length: value
                                    })
                                  }}
                                />
                              </Form.Item>
                            </Form>
                          }
                          placement='right'
                          footer={close => (
                            <>
                              <Button
                                type='link'
                                onClick={() => {
                                  close()
                                }}
                              >
                                确认
                              </Button>
                            </>
                          )}
                        >
                          <Button style={{ marginLeft: '10px' }} type='link'>
                            配置
                          </Button>
                        </PopConfirm>

                        <div style={{ display: 'flex', flexFlow: 'column' }}>
                          <span>最大长度:{x.max_length}</span>
                          <span>最小长度:{x.min_length}</span>
                        </div>
                      </div>
                    )}
                  </div>
                )
              }
            },
            {
              key: 'null_percent',
              width: 170,
              header: () => tips('null值比例', '默认为0，最大为1'),

              render: x => (
                <InputNumber
                  defaultValue={x.null_percent}
                  max={1}
                  min={0}
                  step={0.1}
                  inputProps={{ style: { width: '66px' } }}
                  precision={5}
                  onFocus={e => trDraggable(e, false)}
                  onBlur={e => trDraggable(e, true)}
                  onChange={value => {
                    handleSave({
                      ...x,
                      null_percent: value
                    })
                  }}
                />
              )
            },
            {
              key: 'blank_percent',
              width: 170,
              header: () =>
                tips(
                  'blank值比例',
                  'blank的值(长度为0的字符串) 所占的比例。默认为0 ，最大为1'
                ),
              render: x => (
                <InputNumber
                  defaultValue={x.blank_percent}
                  max={1}
                  min={0}
                  step={0.1}
                  inputProps={{ style: { width: '66px' } }}
                  precision={5}
                  onFocus={e => trDraggable(e, false)}
                  onBlur={e => trDraggable(e, true)}
                  onChange={value => {
                    handleSave({
                      ...x,
                      blank_percent: value
                    })
                  }}
                />
              )
            },
            {
              key: 'pre',
              header: () =>
                tips(
                  '内容前缀',
                  '生成的内容的前缀。比如按照策略生成的的字符串为 001，,002。设置内容前缀的值为test_ 。那么最后生成的字符串为test_001，test_002 。'
                ),
              render: x => (
                <Input
                  onFocus={e => trDraggable(e, false)}
                  onBlur={e => trDraggable(e, true)}
                  defaultValue={x.pre}
                  onChange={value => {
                    handleSave({
                      ...x,
                      pre: value
                    })
                  }}
                />
              )
            },

            {
              key: 'ops',
              header: '',
              width: 60,
              render: x => (
                <div style={{ display: 'flex' }}>
                  <PopConfirm
                    message='确认删除?'
                    footer={close => (
                      <>
                        <Button
                          type='link'
                          onClick={() => {
                            const recordsTmep = records.filter(
                              item => item.order !== x.order
                            )
                            setRecords(recordsTmep)
                            console.log(records)
                            close()
                          }}
                        >
                          删除
                        </Button>
                        <Button type='text' onClick={close}>
                          取消
                        </Button>
                      </>
                    )}
                    placement='top-start'
                  >
                    <Icon style={{ cursor: 'pointer' }} type='close' />
                  </PopConfirm>
                  <Bubble content='复制此行'>
                    <Icon
                      type='copy'
                      style={{ cursor: 'pointer' }}
                      onClick={() =>
                        setRecords([...records, { ...x, order: getId() }])
                      }
                    />
                  </Bubble>
                </div>
              )
            }
          ]}
          bottomTip={
            <Button
              style={{ marginLeft: '18px' }}
              icon='plus'
              size='l'
              onClick={() => setRecords([...records, { ...defaultRecords }])}
            ></Button>
          }
          addons={[
            scrollable({
              maxHeight: 360
            }),
            draggable({
              onDragEnd: (records, context) => {
                setRecords(records)
              },
              onDragStart: context => console.log(context)
            })
          ]}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          type='primary'
          onClick={() => {
            let isDirty = false
            const relData = records.map((item, index) => {
              if (!verName(`${item.order}_col_name`, item.col_name)) {
                isDirty = true
                return item
              }
              if (
                item.col_type !== 'TYPE_JSON' &&
                !verStrategy(`${item.order}_strategy`, item.strategy)
              ) {
                isDirty = true
                return item
              }
              return {
                ...item,
                order: index
              }
            })
            if (isDirty) {
              return
            }
            setData(relData)
            console.log('col-data:', relData)
            setShow(false)
          }}
        >
          确定
        </Button>
        <Button type='weak' onClick={close}>
          取消
        </Button>
      </Modal.Footer>
    </Modal>
  )
})

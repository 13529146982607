import React, { memo, useState, useEffect } from 'react'
import { Icon, Collapse } from 'tea-component'
import { Handle, Position } from 'react-flow-renderer'
import { ResizableBox } from 'react-resizable'

export default memo(({ data, id, isConnectable }) => {
  // console.log(1, data)
  return (
    <div>
      <Collapse
        id='groupA1'
        className='groupA-main'
        defaultActiveIds={['1']}
        onActive={e => {
          const hidden = e.length === 0 ? true : false
          data.onHide(hidden)
        }}
      >
        <Collapse.Panel id='1' title='数据组件'>
          <ResizableBox
            width={data.width}
            height={data.height}
            minConstraints={[100, 100]}
            maxConstraints={[1200, 1000]}
          ></ResizableBox>
        </Collapse.Panel>
      </Collapse>

      <Handle
        type='source'
        position={Position.Right}
        id='a'
        isConnectable={isConnectable}
      />
    </div>
  )
})

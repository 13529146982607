import moment from 'moment'

export const groupANode = {
  id: 'GroupA1',
  type: 'GroupA',
  position: { x: 100, y: 100 },
  className: 'light',
  dragHandle: '.tea-accordion__header',
  selectable: false
}

//表默认配置
export const mockData = {
  name: 'mock',
  out_format: 'json',
  output: 'file',
  partitionNums: 1,
  partitionStart: moment(Date.now()),
  partitionUnit: 'now',
  totalLines: 100000,
  bucketNums: 1,
  col_kv_split: '=',
  col_null_value: null,
  col_split: '`',
  output_kafka_topic: '',
  columns: [],
  dependInfo: []
}
//数据集默认配置
export const defaultDataSet = {
  name: 'dataSet',
  dataset_url: '',
  dataset_format: 'json',
  col_split: '`',
  col_kv_split: '=',
  position_in_dataset: [],
  need_keys_in_dataset: []
}

//依赖表默认配置
export const defaultDepend = {
  edgeId: 0,
  dependType: '',
  circle: false,
  table_cols: [],
  dataset_format: '',
  col_split: '',
  col_kv_split: '',
  percent: 0,
  null_value_str: null,
  dataset_url: '',
  need_keys_in_dataset: [],
  position_in_dataset: [],
  depend_table: '',
  depend_table_cols: []
}
//列默认配置
export const defaultCol = {
  order: 0,
  col_name: '',
  col_type: 'TYPE_STRING',
  strategy: 'string_random',
  null_percent: 0,
  blank_percent: 0,
  pre: '',
  json_col_def: null,
  max_value: 0,
  min_value: 0,
  precision: -1,
  max_length: 10,
  min_length: 1,
  has_letters: true,
  has_numbers: true,
  enumValueWeight: '',
  assignValue: ''
}

//列Json默认配置 
export const defaultColJson = {
  cols_from_self_table: [],
  excluded_keys: [],
  filtered_keys: [],
  mix_json_builders: [],
  mix_json_keys: [],
  simpleJsonColDefs: []
}

//kafka默认配置
export const defaultKafka = {
  "acks": "1",
  "batch.size": "65536",
  "bootstrap.servers": "",
  "buffer.memory": "4194304",
  "kafkaPwd": "",
  "kafkaUserName": "",
  "key.serializer": "",
  "linger.ms": "50",
  "retries": "1",
  "value.serializer": ""
}


//select默认配置 列类型
export const colType = [
  {
    text: '整形',
    value: 'TYPE_INT'
  },
  {
    text: '长整形',
    value: 'TYPE_BIGINT'
  },
  {
    text: '字符串',
    value: 'TYPE_STRING'
  },
  {
    text: 'json类型',
    value: 'TYPE_JSON'
  }
]

//select默认配置 策略
export const colStrategy = (tips) => {
  return [
    {
      text: tips(
        '自增字符id',
        '定长11位。该策略下最大支持32*32个并发'
      ),
      value: 'auto_increment'
    },
    {
      text: tips('生成穷举值', '根据配置生成穷举值'),
      value: 'enum_value_weight'
    },
    {
      text: '随机国内ip',
      value: 'build_ip'
    },
    {
      text: '10位时间戳',
      value: 'datetime_mils_10'
    },
    {
      text: '13位时间戳',
      value: 'datetime_mils_13'
    },
    {
      text: tips('随机地址', '随机生成一个中国地址'),
      value: 'mock_chinese_address'
    },
    {
      text: tips('随机身份证', '随机生成中国的身份证'),
      value: 'mock_chinese_idcard_number'
    },
    {
      text: '随机中文名字',
      value: 'mock_chinese_name'
    },
    {
      text: '随机英文名字',
      value: 'mock_english_name'
    },
    {
      text: tips('日期格式', '按yyyyMMdd的格式生成时间'),
      value: 'datetime_yyyymmdd'
    },
    {
      text: tips(
        '带时间日期格式',
        '按yyyy-MM-dd HH:mm:ss的格式生成时间'
      ),
      value: 'datetime_yyyymmddhhmmss'
    },
    {
      text: '随机字符串',
      value: 'string_random'
    },
    {
      text: '随机数字',
      value: 'digital_random'
    },
    {
      text: '指定字符串',
      value: 'string_assign'
    },
    {
      text: '自增随机字符串',
      value: 'auto_increment_rand'
    },
    {
      text: '分区当前时间',
      value: 'datetime_mils_13_bynow'
    },
    {
      text: '时间戳随机字符串',
      value: 'string_random_mils'
    }
  ]
}

//依赖类型
export const dependType = [
  {
    text: '自依赖',
    value: 'self_partition'
  },
  {
    text: '表分区依赖',
    value: 'other_partition'
  },
  {
    text: '表数据依赖',
    value: 'other_table'
  },
  {
    text: '数据集依赖',
    value: 'outer_dataset'
  }
]